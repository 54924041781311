import React from "react";
import Logo from "../assets/logo.png";
const Header = () => {
  return (
    <header className="*:py-8 scroll-smooth">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <a href="#">
            <img src={Logo} width={120} height={30} alt="" />
          </a>
          <a href="#contact">
            <button className="btn btn-sm ">Work With Me</button>{" "}
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
