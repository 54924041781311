import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import {
  FaWhatsapp,
  FaChalkboard,
  FaEnvelope,
  FaArrowAltCircleUp,
  FaLinkedin,
} from "react-icons/fa";

const Services = () => {
  return (
    <section className="section" id="contact">
      <div className="container mx-auto mt-70">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1  mb-12 lg:mb-0 "
          >
            <h2 className="h2 text-neutral-400 ">Contact Me</h2>
            <h3 className="h3 max-w-[455px] mb-16">Lets Work Together</h3>
            <a href="#home">
              <button className="btn btn-sm">
                <FaArrowAltCircleUp />
              </button>
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <div>
              <div className="border-b border-white/20 h-[60px] mb-[38px] flex">
                <div className="max-w-[476px] ">
                  <a href="tel:+923333930621">
                    <h4 className=" text-[20px]  tracking-wider font-primary font-semibold">
                      <FaWhatsapp /> +92-333-3930621
                    </h4>{" "}
                  </a>
                </div>
              </div>
              <div className="border-b border-white/20 h-[60px] mb-[38px] flex">
                <div className="max-w-[476px] ">
                  <a href="mailto:mrabdullah.k007@gmail.com">
                    <h4 className=" text-[20px]  tracking-wider font-primary font-semibold">
                      <FaEnvelope /> mrabdullah.k007@gmail.com
                    </h4>
                  </a>
                </div>
              </div>
              <div className="border-b border-white/20 h-[60px] mb-[38px] flex">
                <div className="max-w-[476px] ">
                  <a href="https://www.gotodevtech.com" target="blank">
                    <h4 className=" text-[20px]  tracking-wider font-primary font-semibold">
                      <FaChalkboard /> www.gotodevtech.com
                    </h4>
                  </a>
                </div>
              </div>
              <div className="border-b border-white/20 h-[60px] mb-[38px] flex">
                <div className="max-w-[476px] ">
                  <a href="https://www.linkedin.com/in/a-bdul4" target="blank">
                    <h4 className=" text-[20px]  tracking-wider font-primary font-semibold">
                      <FaLinkedin /> Abdullah Khan
                    </h4>
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
