import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const About = () => {
  return (
    <section className="section" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <h2 className="h2 text-neutral-400">About me.</h2>
            <h3 className="h3 mb-4">
              I'm a Freelance Developer and an IT Specialist.
            </h3>
            <p className="mb-6">
              I am a dedicated Freelance FUll stack Developer and IT Specialist
              with a passion for crafting seamless digital experiences. With a
              focus on excellence and innovation, I specialize in delivering
              high-quality web development and IT solutions tailored to meet the
              unique needs of clients. Let's collaborate to turn your ideas into
              reality.
            </p>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 bg-about bg-contain bg-no-repeat h-[450px] mix-blend-lighten bg-top "
          ></motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
