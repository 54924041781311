import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import img1 from "../assets/portfolio-img1.png";
import img2 from "../assets/portfolio-img2.png";
import img3 from "../assets/portfolio-img3.png";

const Work = () => {
  return (
    <section className="section" id="work">
      <div className="container mx-auto ">
        <div className="flex flex-col lg:flex-row gap-x-10">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0"
          >
            <div>
              <h2 className="h2 leading-tight text-neutral-400">
                My Latest <br /> Work
              </h2>
              <p className=" max-w-sm mb-16">
                As an IT Support Specialist and Full stack developer, I create
                dynamic, responsive web applications using React.js, Tailwind
                CSS, and Vite. I focus on user-friendly interfaces, efficient
                solutions, and design aesthetics.
              </p>
            </div>
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              <img
                className="group-hover:scale-125 transition-all duration-500"
                src={img1}
                alt=""
              ></img>
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                <span className="text-gradient">Cross Platform Mobile App</span>
              </div>
              <div className="absolute -bottom-full left-12 group-hover:bottom-10 transition-all duration-700 z-50">
                <span className="text-xl text-white">
                  A cross Platform Carpooling App Named SPLITLY with UI/UX
                  design included.
                </span>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-14"
          >
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              <img
                className="group-hover:scale-125 transition-all duration-500"
                src={img2}
                alt=""
              ></img>
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                <span className="text-gradient">Full Stack Website</span>
              </div>
              <div className="absolute -bottom-full left-12 group-hover:bottom-10 transition-all duration-700 z-50">
                <span className="text-xl text-white">
                  A Real estate webite using MERN stack.{" "}
                  <a
                    href="https://productdata.co/"
                    target="blank"
                    className="font-bold"
                  >
                    productdata.co
                  </a>
                </span>
              </div>
            </div>
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              <img
                className="group-hover:scale-125 transition-all duration-500"
                src={img3}
                alt=""
              ></img>
              <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                <span className="text-gradient">Front End Website</span>
              </div>
              <div className="absolute -bottom-full left-12 group-hover:bottom-10 transition-all duration-700 z-50">
                <span className="text-xl text-white">
                  A responsive Front end website developed for a software House.{" "}
                  <a
                    href="https://www.gotodevtech.com"
                    target="blank"
                    className="font-bold"
                  >
                    Gotodevtech.com
                  </a>
                </span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
