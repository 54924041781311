import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const services = [
  {
    name: "Front End Development",
    description:
      "I specialize in creating engaging web experiences that captivate and convert. From concept to deployment, my front-end development expertise ensures your website stands out with seamless navigation and stunning visuals.",
  },
  {
    name: "Full Stack Development",
    description:
      " I craft dynamic, responsive web applications by leveraging MongoDB, Express.js, React, and Node.js. My expertise ensures seamless integration and a smooth user experience from front-end to back-end. ",
  },
  {
    name: "IT Management",
    description:
      "Efficiently managing your IT infrastructure and operations, I provide strategic solutions that optimize performance and ensure seamless business continuity. From network administration to cybersecurity. ",
  },
  {
    name: "Networking",
    description:
      "Providing reliable and efficient networking solutions, I specialize in designing, implementing, and maintaining robust network infrastructures.  ",
  },
  {
    name: "UI/UX Design",
    description:
      "I specialize in creating seamless digital experiences that enhance user satisfaction and engagement. From wireframes to prototypes, my design solutions prioritize usability and aesthetics.",
  },
];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1  mb-12 lg:mb-0 "
          >
            <h2 className="h2 text-neutral-400 ">What I Do</h2>
            <h3 className="h3 max-w-[455px] mb-16">
              I'm a Developer and an IT Specialist.
            </h3>
            <a href="#work">
              <button className="btn btn-sm">see my work</button>{" "}
            </a>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <div>
              {services.map((service, index) => {
                const { name, description } = service;
                return (
                  <div
                    className="border-b border-white/20 h-[146px] mb-[38px] flex"
                    key={index}
                  >
                    <div className="max-w-[476px]">
                      <h4 className="text-[20px] tracking-wider font-primary font-semibold">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight mb-6">
                        {" "}
                        {description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
