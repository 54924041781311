import React from "react";
import { FaLinkedin, FaChalkboard } from "react-icons/fa";
import avatar from "../assets/avatar.png";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Banner = () => {
  return (
    <section
      className="min-h-[85vh] lg:min-h-[78vh] flex items-center"
      id="home"
    >
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
          <div className="flex-1 text-center font-secondary lg:text-left">
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[55px] font-bold leading-[1.5] lg:text-[80px]"
            >
              ABDULLAH
            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading=[1]"
            >
              <span className="text-white mr-3">I am</span>
              <TypeAnimation
                sequence={[
                  "a Developer",
                  2000,
                  "an IT Specialist",
                  2000,
                  "a Designer",
                  2000,
                ]}
                speed={50}
                className="text-neutral-400"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn("up", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-8 max-w-lg mx-auto lg:mx-0"
            >
              Creating visually stunning and technically robust digital
              solutions with a blend of front-end development, IT support, and
              design expertise.
            </motion.p>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0"
            >
              <a href="#contact">
                <button className="btn btn-lg">Contact me</button>
              </a>
              <a href="#work" className="text-gradient btn-link">
                my portfolio
              </a>
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0"
            >
              <a href="https://www.linkedin.com/in/a-bdul4" target="blank">
                <FaLinkedin />
              </a>
              <a href="https://www.gotodevtech.com/" target="blank">
                <FaChalkboard />
              </a>
            </motion.div>
          </div>
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            class="flex items-center justify-center h-full "
          >
            <img src={avatar} width={350} alt="" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
